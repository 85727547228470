body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'trebuchet ms', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* styles.css */

header {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 8px;
}

.header-icon-container {
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
}

.header-icon {
  width: 160px;
  height: auto;
}

.header-icon-small {
  width: 80px;
  height: auto;
}

.header-description {
  font-weight: bold;
}

.body-container {
  margin-bottom: 64px;
}

footer {
  margin-top: 0px;
  padding-bottom: 16px;
  background-size: 100% 100%;
}

.privacy-policy {
 text-align: start;
 z-index: 9999;
 position: absolute;
 top: 0;
 left: 0;
 padding: 16px;
 background-color: white;
}

.privacy-close-btn {
  position: fixed;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.additional-body-item-container {
  margin-top: 16px;
}

.additional-body-item-inner img {
  width: 160px;
  height: auto;
}

.additional-body-item-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.additional-body-item-link {
  margin: auto 0 auto 0;
}

.body-item-list-container {
  margin: 64px auto 0 auto;
}

.body-item-list {
  display: flex;
  justify-content: space-between;
  margin: 64px 25% 0 25%;
}

.body-item-list-banner {
  text-align: left;
  padding-left: 20%;
}

.body-item-list-banner img {
  width: 512px;
  border-radius: 10px;
}

.additional-body-item-container {
  display: flex;
  justify-content: space-between;
  margin: 64px 10% 0 10%;
}

.body-item {
  width: 160px;
  height: auto;
}

.body-item:hover {
  cursor: pointer;
}

.body-item img {
  width: 160px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Black background with transparency */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensures the popup is displayed above other elements */
  color: white;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button img {
  width: 32px;
}

.close-button:hover {
  cursor: pointer;
}

.drowdown select {
  padding: 4px;
  width: 160px;
  border-radius: 8px;
  border-color: #ccc;
}

.popup-inner {
  position: relative;
  background-color: white;
  color: black;
  border-radius: 10px; /* Rounded corners */
  width: 80%;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-y: auto;
}

.popup-title {
  font-size: 24px;
  margin-bottom: 32px;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin: 0 25% 32px 25%;
}

.product-list-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 5%;
}

.product {
  margin: 16px;
  width: 200px;
}

.product img {
  width: 128px;
  height: auto;
  border-radius: 6px;
}

.product:hover {
  cursor: pointer;
}

.product-title {
  margin-top: 8px;
  margin-bottom: 12px;
}

.product-info {
  font-family: 'Courier New', monospace;
  font-size: 12px;
}

.product-description-images img {
  width: 90%;
  border-radius: 16px;
}

.footer-logo {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.footer-logo img {
  width: 120px;
}

.footer-contacts {
  margin-bottom: 16px;
}

.footer-contacts img {
  width: 24px;
  margin: 0 16px 0 16px;
}

.privacy-btn {
  cursor: pointer;
}

.footer-copy-rights {
  font-size: 12px;
}

/* Beginning of product detail */
.product-detail-container {

}

.product-detail {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 32px;
  padding-left: 16px;
}

.product-detail-left {
  flex: 2;
  margin-right: 6px;
}

.product-detail-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-image {
  width: 192px;
  align-self: center;
}

.main-image img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 6px;
}

.small-images {
  display: flex;
  align-self: center;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}

.small-images img {
  width: 64px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.download-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  margin-top: 12px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.download-button:hover {
  background-color: #eee;
}

.download-button img {
  width: 160px;
}

.left-align {
  text-align: start;
  padding-left: 8px;
}

.product-detail-right {
  flex: 5;
  /* margin-left: 20px; */
}

.product-detail-header {
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
}

.product-detail-bottom {
  margin-top: 0px;
}

.product-name {
  font-size: 18px;
  font-weight: bold;
}

.product-description {
  margin-bottom: 10px;
}

.product-description iframe {
  width: 100%;
  height: 500px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.product-description .long-iframe {
  height: 1100px !important;
}

.product-type {
  font-style: italic;
}

.translation-btn {
  display: inline-block;
  padding: 10px 14px;
  background-color: #f5f5dc;
  color: black;
  border-radius: 50%;
  border-style: solid;
  border-color: #aaa;
  box-shadow: none;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-right: 12px;
}

.translation-btn:hover {
  background-color: rgb(247, 181, 83);
  cursor: pointer;
}

.translation-btn-container {
  margin-left: auto;
}

.back-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.back-button img {
  width: 128px;
}

.fixed-top-left {
  position: absolute;
  top: -34px;
  left: 0px;
}
/* End of product detail */

/* Beginning of scrollbar */

/* For WebKit browsers */
::-webkit-scrollbar {
  width: 8px; /* width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #E0E0E0; /* color of the track */
  border-radius: 8px; /* rounded corners on track */
}

::-webkit-scrollbar-thumb {
  background-color: white; /* color of the thumb (the scrollbar itself) */
  border-radius: 10px; /* rounded corners on thumb */
  border: 1px solid #CCC;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: white #F0F0F0;
}

/* End of scrollbar */